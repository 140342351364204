.diagonal {
  padding: 10px 20px; /* Button padding */
  border: none; /* Remove button border */
  clip-path: polygon(0 0, 95% 0, 100% 16%, 100% 100%, 5% 100%, 0 84%); /* Diagonal shape */
}

.diagonal2 {
  padding: 15px 20px 30px 15px; /* Button padding */
  border: none; /* Remove button border */
  clip-path: polygon(0 0, 95% 0, 100% 20%, 100% 100%, 5% 100%, 0 80%); /* Diagonal shape */
  white-space: nowrap;
}

/* esses stilos são do header, depois mudar para outro arquivo */
.slide-container {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-in-out;
}

.slide-down {
  max-height: 700px; /* Adjust the desired max height of the container */
}

.slide-up {
  max-height: 0;
}

/* estilos do carrossel mudar tbm p outro arquivo */
/* .glide__slide[class*="active"] {
  height: 300px;
  
} */
/* .glide__slide {
  height: 300px;
  overflow: hidden;
} */

.glide__bullet[class*="active"] {
  background-color: white;
}

/*estilo carrosel preços - mudar pra outro arquivo  */
.bg-special-color {
  background-color: #fff;
}

.text-special-text-color {
  color: #000;
}

.carousel__slide .special-card .border {
  border-color: #000000;
}

/* .carousel__slide .special-card button {
  background-color: black;
  color: #fff;
}

.carousel__slide button:not(.special-card) {
  background-color: #fff;
  color: #000;
} */
