/* reset */
/* button,
h3 {
  all: unset;
} */

.AccordionRoot {
  width: 300px;
  background-color: black;
  color: white;
}

.AccordionItem {
  overflow: hidden;
  margin-top: 1px;
  
}

.AccordionItem:first-child {
  margin-top: 0;
}

/* .AccordionItem:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
} */

.AccordionItem:focus-within {
  position: relative;
  z-index: 1;
}

.AccordionHeader {
  display: flex;
}

.AccordionTrigger {
  font-family: inherit;
  font-weight: bold;
  font-size: medium;
  text-transform: uppercase;
  background-color: transparent;
  padding: 0 20px;
  height: 50px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  color: white;
  background-color: black;
  border: 3px solid white;
  margin-top: 4px;
  margin-bottom: 4px;
}

.AccordionTrigger:hover {
  font-weight: 1000;
}

.AccordionContent {
  overflow: hidden;
  color: white;
  background-color: black;
}
.AccordionContent[data-state='open'] {
  animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionContent[data-state='closed'] {
  animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
}

.AccordionContentText {
  padding: 10px 15px;
}

.AccordionChevron {
  color: white;
  transition: transform 300ms cubic-bezier(0.87, 0, 0.13, 1);
}
.AccordionTrigger[data-state='open'] > .AccordionChevron {
  transform: rotate(180deg);
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}

@media screen and (min-width: 420px) {
  .AccordionRoot {
    width: 80vw;
  }
}