

/* reset */
/* button,
fieldset,
input {
  all: unset;
} */

.TabsRoot {
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 3px solid black;
}

.TabsList {
  flex-shrink: 0;
  display: flex;
  border-bottom: 1px solid #241E20;
}

.TabsTrigger {
  font-family: inherit;
  background-color: white;
  padding: 0px 5px;
  height: 45px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  line-height: 1;
  color: #241E20;
  user-select: none;
}
/* .TabsTrigger:first-child {
  border-top-left-radius: 6px;
}
.TabsTrigger:last-child {
  border-top-right-radius: 6px;
} */
.TabsTrigger:hover {
  color: white;
  background-color: #241E20;
}
.TabsTrigger[data-state='active'] {
  background-color: #241E20;
  color: white;
}
.TabsTrigger:focus {
  position: relative;
  box-shadow: 0 0 0 2px #241E20;
  font-weight: 1000;
}

.TabsContent {
  flex-grow: 1;
  padding: 20px;
  background-color: white;
  outline: none;
}
.TabsContent[data-state='active'] {
  background-color: #241E20;
  color: white;
}
/* .TabsContent:focus {
  box-shadow: 0 0 0 2px black;
} */

.Text {
  margin-top: 0;
  margin-bottom: 20px;
  color: black;
  font-size: 15px;
  line-height: 1.5;
}

@media screen and (min-width: 640px) {
  .TabsRoot {
    width: 500px;
  }
}

@media screen and (min-width: 1280px) {
  .TabsRoot {
    width: 600px;
  }
}

